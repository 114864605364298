<style scoped>
@media screen and (min-width: 601px) {
  .texto {
    padding: 80px;
  }
}

@media screen and (max-width: 600px) {
  .texto {
    padding: 10px;
  }
}
</style>

<template>
  <div>
    <Loader v-if="loading"/>
    <div class="site-container my-4">
      <faq-page :admin="false" />
    </div>

    <!-- DARK CONTAINER -->
    <div
      class="text-white"
      style="padding-top: 40px; padding-bottom: 120px"
      :style="{
        backgroundSize: '98%',
        backgroundColor: '#111',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'left 60px',
        backgroundImage: `url(${require('../assets/images/bg-vector-rounded-yellow-height.svg')})`,
      }">
      <div class="site-container">
        <b-row>
          <b-col sm="6">
            <div class="texto">
              <p style="line-height: 1.2; font-size: 1.8rem; font-weight: 600">Ainda com dúvidas?<br />Fale conosco!</p>
              <p style="line-height: 1.4">
                Logo a baixo você encontra nossas informações e número para contato, ficaremos muito felizes em ajudar você! Ou se preferir, nos envie um email com as suas dúvidas e nossa equipe
                retornará o mais breve possível!
              </p>
              <p>Rua Buenos Aires, 459, sala 202, Ponta Aguda - Blumenau/SC - 89051-050</p>
              <p>
                +55 47 3237-3041 <b-icon icon="telephone-fill" />
                <span class="mx-2" />
              </p>
            </div>
          </b-col>
          <b-col sm="5">
            <b-card class="p-2">
              <validation-observer ref="simpleRules">
                <div>
                  <label>Nome</label>
                  <validation-provider #default="{ errors }" name="Nome" rules="required">
                    <b-form-input id="input-1" v-model="form.name" placeholder="Nome" :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>

                <div>
                  <label>E-mail</label>
                  <validation-provider #default="{ errors }" name="E-mail" rules="required|email">
                    <b-form-input id="input-2" v-model="form.email" type="email" placeholder="seuemail@exemplo.com.br" :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>

                <div>
                  <label>Mensagem</label>
                  <validation-provider #default="{ errors }" name="Mensagem" rules="required">
                    <b-form-textarea id="input-3" v-model="form.message" placeholder="Escreva sua mensagem..." required />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>

                <div class="mt-2">
                  <b-button variant="primary" style="width: 100%" @click="sendForm" :disabled="loading">Enviar</b-button>
                </div>
              </validation-observer>
            </b-card>
          </b-col>
          <b-col sm="1">
          </b-col>
        </b-row>
      </div>
    </div>

    <!-- ORANGE CARD -->
    <div class="site-container">
      <newsletter-card style="margin-top: -80px" />
    </div>

    <Footer />
  </div>
</template>

<script>
import toast from '@/mixins/toast'
import { BRow, BCol, BCard, BButton, BFormInput, BFormTextarea } from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Loader  from '@/components/Loader.vue'

import FaqService from '@/services/FaqService'
import Footer from '../components/parts/footer/Footer.vue'
import NewsletterCard from '../components/NewsletterCard.vue'
import FaqPage from '../../pages/faq/FaqPage.vue'

export default {
  mixins: [toast],

  components: {
    FaqPage,
    Footer,
    NewsletterCard,
    BRow,
    BCol,
    BCard,
    BButton,
    BFormInput,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    Loader,
  },

  data() {
    return {
      email,
      required,
      loading: false,
      form: { name: ' ', email: ' ', message: ' ' },
    }
  },

  methods: {
    resetForm() {
      this.form = { message: undefined, email: undefined, name: undefined }
    },

    async sendForm() {
      try {
        this.$refs.simpleRules.validate().then(async success => {
          if (success) {
            this.loading = true
            await FaqService.contact(this.form)
            this.loading = false
            this.msgSuccess()
            //this.resetForm()
          }
        })
      } catch (e) {
        this.msgError(e.message)
        this.loading = false
      }
    },
  },
}
</script>
