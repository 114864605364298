var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('Loader'):_vm._e(),_c('div',{staticClass:"site-container my-4"},[_c('faq-page',{attrs:{"admin":false}})],1),_c('div',{staticClass:"text-white",staticStyle:{"padding-top":"40px","padding-bottom":"120px"},style:({
      backgroundSize: '98%',
      backgroundColor: '#111',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'left 60px',
      backgroundImage: ("url(" + (require('../assets/images/bg-vector-rounded-yellow-height.svg')) + ")"),
    })},[_c('div',{staticClass:"site-container"},[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('div',{staticClass:"texto"},[_c('p',{staticStyle:{"line-height":"1.2","font-size":"1.8rem","font-weight":"600"}},[_vm._v("Ainda com dúvidas?"),_c('br'),_vm._v("Fale conosco!")]),_c('p',{staticStyle:{"line-height":"1.4"}},[_vm._v(" Logo a baixo você encontra nossas informações e número para contato, ficaremos muito felizes em ajudar você! Ou se preferir, nos envie um email com as suas dúvidas e nossa equipe retornará o mais breve possível! ")]),_c('p',[_vm._v("Rua Buenos Aires, 459, sala 202, Ponta Aguda - Blumenau/SC - 89051-050")]),_c('p',[_vm._v(" +55 47 3237-3041 "),_c('b-icon',{attrs:{"icon":"telephone-fill"}}),_c('span',{staticClass:"mx-2"})],1)])]),_c('b-col',{attrs:{"sm":"5"}},[_c('b-card',{staticClass:"p-2"},[_c('validation-observer',{ref:"simpleRules"},[_c('div',[_c('label',[_vm._v("Nome")]),_c('validation-provider',{attrs:{"name":"Nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"input-1","placeholder":"Nome","state":errors.length > 0 ? false : null},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',[_c('label',[_vm._v("E-mail")]),_c('validation-provider',{attrs:{"name":"E-mail","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"input-2","type":"email","placeholder":"seuemail@exemplo.com.br","state":errors.length > 0 ? false : null},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',[_c('label',[_vm._v("Mensagem")]),_c('validation-provider',{attrs:{"name":"Mensagem","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"input-3","placeholder":"Escreva sua mensagem...","required":""},model:{value:(_vm.form.message),callback:function ($$v) {_vm.$set(_vm.form, "message", $$v)},expression:"form.message"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"mt-2"},[_c('b-button',{staticStyle:{"width":"100%"},attrs:{"variant":"primary","disabled":_vm.loading},on:{"click":_vm.sendForm}},[_vm._v("Enviar")])],1)])],1)],1),_c('b-col',{attrs:{"sm":"1"}})],1)],1)]),_c('div',{staticClass:"site-container"},[_c('newsletter-card',{staticStyle:{"margin-top":"-80px"}})],1),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }